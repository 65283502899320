import React from "react";
import {Link} from "gatsby";

import Body from "components/layout/Body/Body";
import SEO from "../../../components/seo";

const Euroviajar = () => (
	<Body>
		<SEO title="3D Furniture - Portfolio - Kevin Mamaqi Kapllani" />
		<h1>Euroviajar.com</h1>
		<p>About me.</p>
		<Link to="/">Go back to the homepage</Link>
	</Body>
);

export default Euroviajar;
